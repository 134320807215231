import React from "react";

const Terms = () => {
  return (
    <>
      <div className="w-section">
        <div className="w-container">
          <br />
          <div className="heading">Terms of Use</div>
          <p class="paragraph" style={{ marginLeft: 25, marginRight: 25 }}>
            Last Updated: January 10th, 2024
            <br />
            <br />
            <b>Introduction</b>
            <br />
            Welcome to Honest Link ("Company", "we", "our", "us"). These Terms of Use (“Terms”) govern your use of honest.stream and our related products and services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy.
            <br />
            <br />
            <b>Use of Services</b>
            <br />
            a. The Services are intended for users who are at least 14+ years old. By using our Services, you represent and warrant that you are of legal age to form a binding contract with the Company.
            <br />
            b. You agree to use the Services only for lawful purposes and in accordance with these Terms. You may not use the Services in any manner that could damage, disable, overburden, or impair the Service or interfere with any other party's use of the Services.
            <br />
            <br />
            <b>Intellectual Property Rights</b>
            <br />
            The content, features, functionality, and design elements of the Services are and will remain the exclusive property of Honest Link and its licensors. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Honest Link.
            <br />
            <br />
            <b>User Contributions</b>
            <br />
            a. The Services may contain message boards, chat rooms, personal web pages or profiles, forums, and other interactive features that allow users to post, submit, publish, display, or transmit content or materials to us or other users ("User Contributions").
            <br />
            b. Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Services, you grant us and our affiliates a license to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material.
            <br />
            <br />
            <b>Links to Other Websites</b>
            <br />
            Our Service may contain links to third-party websites or services that are not owned or controlled by Honest Link. Honest Link has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services.
            <br />
            <br />
            <b>Disclaimer of Warranties</b>
            <br />
            The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Company makes no representations or warranties of any kind, express or implied, as to the operation of their services, or the information, content, or materials included therein.
            <br />
            <br />
            <b>Limitation of Liability</b>
            <br />
            In no event will Honest Link, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.
            <br />
            <br />
            <b>Governing Law</b>
            <br />
            These Terms shall be governed and construed in accordance with the laws of California, without regard to its conflict of law provisions.
            <br />
            <br />
            <b>Changes to Terms</b>
            <br />
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised terms.
            <br />
            <br />
            <b>Contact Information</b>
            <br />
            For any questions about these Terms, please contact us at
            <br />
            <br />
            Honest Link
            <br />
            523 N Crescent Dr Beverly Hills CA 90210
            <br />
            jason@honest.stream
            <br />
            +1-510-847-8748
          </p>
        </div>
        <div style={{ height: 100 }}></div>
      </div>
      {/* <a class="w-webflow-badge" href="https://webflow.com?utm_campaign=brandjs">
        <img src="https://d3e54v103j8qbb.cloudfront.net/img/webflow-badge-icon.f67cd735e3.svg" alt="" style={{ marginRight: "8px", width: "16px" }} />
        <img src="https://d1otoma47x30pg.cloudfront.net/img/webflow-badge-text.6faa6a38cd.svg" alt="Made in Webflow" />
      </a> */}
    </>
  );
};

export default Terms;
