import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import axios from "axios";
import {Helmet} from "react-helmet";

const Message = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const [text, setText] = useState();
  const [subtitle, setSubtitle] = useState("Ask Anything");
  const [focus, setFocus] = useState(false);
  const [photo, setPhoto] = useState(`https://firebasestorage.googleapis.com/v0/b/honest-c986c.appspot.com/o/profilePictures%2FprofPic-${params.name}.jpg?alt=media`);
  const [location, setLocation] = useState("");
  const [qData, setQData] = useState({
    active: true,
    hideBranding: false,
    questionType: 0,
    question: "Send me an anonymous message:",
    epoch: Number(params.number),
    responses: 0,
    views: 0,
    public: true
  });

  const sendResponse = async (data) => {
    await setDoc(doc(db, "responses", params.name, data.questionEpoch, data.epoch), {
      response: data.response,
      questionEpoch: Number(data.questionEpoch),
      epoch: Number(data.epoch),
      ipAddressLocation: data.ipAddressLocation,
    });
  };

  const createView = async (loc) => {
    const time = new Date().getTime().toFixed(0);
    const finalData = {
      // response: text,
      view: true,
      epoch: time,
      questionEpoch: params.number,
      ipAddressLocation: loc,
    };
    await setDoc(doc(db, "responses", String(params.name), String(finalData.questionEpoch), String(finalData.epoch)), {
      // response: data.response,
      view: true,
      questionEpoch: Number(finalData.questionEpoch),
      epoch: Number(finalData.epoch),
      ipAddressLocation: finalData.ipAddressLocation,
    });
    const docSnap = await getDoc(doc(db, "questions", String(params.name), "allQuestions", String(params.number)));
    const data = docSnap.data();
    let views = 0;
    if (!data.views) {
      views = 1;
    } else {
      views = data.views + 1;
    }
    data.views = views;
    await updateDoc(doc(db, "questions", String(params.name), "allQuestions", String(params.number)), data);
  }

  const postQuestion = async (data) => {
    await setDoc(doc(db, "questions", params.name, "allQuestions", params.number), data);
  };

  const Submit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (params.number) {
      const time = new Date().getTime().toFixed(0);
      const data = {
        response: text,
        epoch: time,
        questionEpoch: params.number,
        ipAddressLocation: location,
      };
      sendResponse(data);
      let data1 = {
          ...qData,
          responses: qData.responses ? qData.responses + 1 : 1,
          views: qData.views ? qData.views + 1 : 1,
        };

      postQuestion(data1);
    }

    await axios.post("https://us-central1-honest-c986c.cloudfunctions.net/sendPush", {
      username: params.name,
      app: "honest",
      question: qData.question,
      questionEpoch: qData.epoch,
      time: qData.epoch,
      questionType: qData.questionType,
      questionPub: qData.public,
    }).then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
    
    navigate("/p/sent", {
      state: { name: params.name, number: params.number, pub: qData.public, question: qData.question, time: qData.epoch, questionType: qData.questionType },
    });
  };

  const getLocation = async () => {
    await axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        const data = res.data.city + ", " + res.data.region + ", " + res.data.country;
        setLocation(data);
        createView(data);
      })
      .catch((error) => console.log(error));
  };

  const fetchData = async () => {
    setLoading(true);
    var color = "#EC1187 linear-gradient(to bottom right, #EC1187 0%, #FF8D10 100%)";
    try {
      const docSnap = await getDoc(doc(db, "questions", params.name, "allQuestions", params.number));
      const data = docSnap.data();
      if (data) {
        setQData(data);
        if (data.topColor) color = `${data.topColor} linear-gradient(to bottom right, ${data.topColor} 0%, ${data.bottomColor} 100%)`;
        else if (data.questionType === 1) {
          color = "#2CD27E";
          setSubtitle("Personal feedback");
        } else if (data.questionType === 2) {
          color = "#F88379";
          setSubtitle("This or That");
        } else if (data.questionType === 3) {
          color = "#26A1D5";
          setSubtitle("Recommendations");
        } else if (data.questionType === 4) {
          color = "#949494";
          setSubtitle("Business feedback");
        } else if (data.questionType === 5) {
          color = "#D042F8";
          setSubtitle("Just For Fun");
        } else if (data.questionType === 6) {
          color = "#EC1254";
          setSubtitle("Relationships & Dating");
        }
      } else {
        await postQuestion(qData);
      }
    } catch {
      // console.error("error");
    }
    document.documentElement.style.background = color;
    setLoading(false);    
  };

  /*eslint-disable*/
  useEffect(() => {
    fetchData();
    getLocation();    
  }, []);
  /*eslint-enable*/

  const handleOnError = () => {
    setPhoto("");
  };

  const [count, setCount] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        const randomNum = Math.floor(Math.random() * 6);

        // Based on the random number, decide the operation
        switch (randomNum) {
          case 0:
            return prevCount + 1 > 0 ? prevCount + 1 : 0; // Increment by 1
          case 1:
            return prevCount + 2 > 0 ? prevCount + 2 : 0; // Increment by 2
          case 2:
            return prevCount + 3 > 0 ? prevCount + 3 : 0; // Increment by 3
          case 3:
            return prevCount - 1 > 0 ? prevCount - 1 : 0; // Decrement by 1
          case 4:
            return prevCount - 2 > 0 ? prevCount - 2 : 0; // Decrement by 2
          case 5:
            return prevCount - 3 > 0 ? prevCount - 3 : 0; // Decrement by 3
          default:
            return prevCount; // In case something unexpected happens, return the original value
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Helmet>
            <meta charSet="utf-8" />
            <title>@{params.name}: {qData.question}</title>
            <meta name="description" content="Let's be honest...Join the conversation!" />
        </Helmet>
      {!loading ? (
        <>          
          <div className="container">
            <form className="form" method="post">
              <div className="bubble">
                <div className="header">
                {photo ? (<div className="pfp-container" style={{ backgroundImage: photo }}>
                     <img src={photo} alt="" onError={handleOnError} />
                  </div>) : null}
                  <div style={!photo ? {marginLeft: 10} : null} className="user-container">
                    <div className="username">@{params.name}</div>
                    <div className="prompt">
                      <div className="text">{qData.question}</div>
                      {qData.link && (
                        <Link to={qData.link} className="link">
                          <div className="link-text">{qData.link}</div>
                          {/* <div className="arrow-down" /> */}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="textarea-container">
                  {/* <div className="dice-button">🎲</div> */}
                  <textarea placeholder={qData.public ? "Reply to join the conversation 👀" : "Reply anonymously here"} value={text} onChange={(e) => setText(e.target.value)} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} name="question" autoComplete="off" maxLength="300" />
                </div>
              </div>
              <div className="anonymous-tooltip">Your response is 100% anonymous 🔒</div>
              {text && (
                <button className="submit" type="submit" onClick={Submit} disabled={load}>
                  Send!
                </button>
              )}
            </form>
          </div>
          {!focus && (
            <div className="bottom-container">
              {/* <div className="download-prompt">
                👇 <span className="clickCount">225</span> people just tapped
                the button👇
              </div> */}
              {/* <div className="download-prompt">
                <span className="clickCount">{count} </span> people just tapped
                the button
              </div> */}
              <div style={{ fontWeight: "700", textAlign: "center", fontSize: 17, marginBottom: 16, color: "white" }} className="back">
                {`${count} people just tapped the button 👇`}
              </div>
              <Link className="button download-link pulse" to="https://apps.apple.com/us/app/honest-link/id6464457576" target="_blank">
                Get your own responses!
              </Link>
              {/* <div className="tos-privacy">
                <Link className="tos" to="/p/terms">
                  Terms
                </Link>
                <Link className="privacy" to="/p/privacy">
                  Privacy
                </Link>
              </div> */}
            </div>
          )}
        </>
      ) : (
        <div className="loading">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
};

export default Message;
